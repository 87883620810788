import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
  },
  {
    path: "/login",
    name: "loginLogin",
    component: () => import("../views/login/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/login/register.vue"),
  },
  {
    path: "/inviteUsers",
    name: "inviteUsers",
    component: () => import("../views/inviteUsers.vue"),
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/user.vue"),
  },
  {
    path: "/asset",
    name: "asset",
    component: () => import("../views/asset.vue"),
  },
  {
    path: "/welfare/buy",
    name: "welfare-bur",
    component: () => import("../views/welfare/bur.vue"),
  },
  {
    path: "/signIn",
    name: "signIn",
    component: () => import("../views/signIn.vue"),
  },
  {
    path: "/welfare",
    name: "welfare",
    component: () => import("../views/welfare.vue"),
  },
  {
    path: "/user/invitathumb",
    name: "user-invitathumb",
    component: () => import("../views/user/invitathumb.vue"),
  },
  {
    path: "/user/contactus",
    name: "user-contactus",
    component: () => import("../views/user/contactus.vue"),
  },
  {
    path: "/user/welfare",
    name: "user-welfare",
    component: () => import("../views/user/welfare.vue"),
  },
  {
    path: "/user/savepwd",
    name: "user-savepwd",
    component: () => import("../views/user/savepwd.vue"),
  },
  {
    path: "/user/contactus1",
    name: "user-contactus1",
    component: () => import("../views/user/contactus1.vue"),
  },
  {
    path: "/user/customserice",
    name: "user-customserice",
    component: () => import("../views/user/customserice.vue"),
  },
  {
    path: "/user/assetDetails",
    name: "user-assetDetails",
    component: () => import("../views/user/assetDetails.vue"),
  },
  {
    path: "/user/withsave",
    name: "user-withsave",
    component: () => import("../views/user/withsave.vue"),
  },
  {
    path: "/user/withsave1",
    name: "user-withsave1",
    component: () => import("../views/user/withsave1.vue"),
  },
  {
    path: "/user/withsave2",
    name: "user-withsave2",
    component: () => import("../views/user/withsave2.vue"),
  },
  {
    path: "/user/buylog",
    name: "user-buylog",
    component: () => import("../views/user/buylog.vue"),
  },
  {
    path: "/user/realauth",
    name: "user-realauth",
    component: () => import("../views/user/realauth.vue"),
  },
  {
    path: "/user/bankEdit",
    name: "user-bankEdit",
    component: () => import("../views/user/bankEdit.vue"),
  },
  {
    path: "/user/assetlog",
    name: "user-assetlog",
    component: () => import("../views/user/assetlog.vue"),
  },
  {
    path: "/user/teamlist",
    name: "user-teamlist",
    component: () => import("../views/user/teamlist.vue"),
  },
  {
    path: "/user/guarantee",
    name: "user-guarantee",
    component: () => import("../views/user/guarantee.vue"),
  },
  {
    path: "/user/addressEdit",
    name: "user-addressEdit",
    component: () => import("../views/user/addressEdit.vue"),
  },
  {
    path: "/user/balancelog",
    name: "user-balancelog",
    component: () => import("../views/user/balancelog.vue"),
  },
  {
    path: "/user/wealthEquity",
    name: "user-wealthEquity",
    component: () => import("../views/user/wealthEquity.vue"),
  },
  {
    path: "/user/vip",
    name: "user-vip",
    component: () => import("../views/user/vip.vue"),
  },
  {
    path: "/user/teamPrize",
    name: "user-teamPrize",
    component: () => import("../views/user/teamPrize.vue"),
  },
];

const router = new VueRouter({
  routes,
});

const white = ["/login", "/user/contactus", "/user/contactus1", "/register"];
// 全局前置守卫
router.beforeEach((to, from, next) => {
  // 判断用户是否登录
  const isAuthenticated = localStorage.getItem("vuex_token"); // 这里根据你的逻辑判断
  if (!white.includes(to.path) && !isAuthenticated) {
    next("/login"); // 重定向到登录页面
  } else {
    next(); // 继续路由
  }
});

export default router;
